<template>
  <div class="one_to_one">
    <div class="one_to_one_box">
      <div class="one_to_one_exit" @click="boxExit">
        <img src="../assets/img/X.png" alt="나가기" />
      </div>
      <div class="one_to_one_top_text">
        <p>2023년 기업 채용 현장지원 신청</p>
        <p style="font-size: 20px; line-height: 50px; margin-top: 20px">
          한국산업인력공단 주관, 엑스퍼트컨설팅에서 수행하는
        </p>
        <p style="font-size: 20px; line-height: 0">
          <strong>23년 기업 채용 현장지원 신청페이지입니다.</strong>
        </p>
        <p style="font-size: 20px; line-height: 50px">
          원활한 서비스 제공을 위해 아래 내용을 작성해주시면 감사하겠습니다.
        </p>
        <img
          src="../assets/img/ApplicationForParticipation.png"
          alt="1:1문의"
          class="one_to_one_top_img"
        />
      </div>
      <div class="one_to_one_bot_text">
        <fieldset>
          <legend>신청 기업명</legend>
          <input
            type="text"
            class="one_to_one_input_text"
            @focus="inputClick(0)"
            @blur="inputRemove(0)"
            v-model="corname"
            placeholder="기업명"
          />
        </fieldset>
        <fieldset>
          <legend>기업유형</legend>
          <select
            class="one_to_one_input_text"
            @focus="inputClick(1)"
            @blur="inputRemove(1)"
            v-model="corgubun"
          >
            <option value="" selected>선택해주세요.</option>
            <option value="101">민간(50인미만)</option>
            <option value="102">민간(50~300인)</option>
            <option value="103">민간(300인이상)</option>
            <option value="1">공기업</option>
            <option value="2">준정부기관</option>
            <option value="3">기타공공기관</option>
            <option value="4">지방공기업</option>
            <option value="5">지방출자출연기관</option>
            <option value="6">기타 공직유관단체</option>
          </select>
        </fieldset>
        <fieldset>
          <legend>담당자</legend>
          <input
            type="text"
            class="one_to_one_input_text"
            @focus="inputClick(2)"
            @blur="inputRemove(2)"
            @keyup="onlyKorean"
            v-model="workernm"
            placeholder="담당자"
          />
        </fieldset>
        <fieldset class="rank_box">
          <legend>부서</legend>
          <input
            type="text"
            class="one_to_one_input_text"
            @focus="inputClick(3)"
            @blur="inputRemove(3)"
            v-model="rank1"
            placeholder="부서"
            maxlength="50"
          />
        </fieldset>
        <fieldset class="rank_box">
          <legend>직급</legend>
          <input
            type="text"
            class="one_to_one_input_text"
            @focus="inputClick(4)"
            @blur="inputRemove(4)"
            @keyup="onlyKorean"
            v-model="rank2"
            placeholder="직급"
            maxlength="10"
          />
        </fieldset>
        <fieldset>
          <legend>연락처</legend>
          <input
            type="number"
            class="one_to_one_input_text"
            maxlength="11"
            @focus="inputClick(5)"
            @blur="inputRemove(5), numCheck()"
            v-model="contactno"
            placeholder="010-0000-0000"
          />
          <small
            class="length_check"
            style="color: #e90000"
            v-show="!numbollean"
            >숫자만 입력해주세요.</small
          >
        </fieldset>
        <fieldset>
          <legend>이메일</legend>
          <input
            type="text"
            class="one_to_one_input_text"
            @focus="inputClick(6)"
            @blur="inputRemove(6), emailCheck()"
            v-model="workeremail"
            placeholder="email"
          />
          <small
            class="length_check"
            style="color: #e90000"
            v-show="!emailBolean"
            >이메일 형식이 맞지 않습니다.</small
          >
        </fieldset>
        <fieldset
          style="
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 10px 0;
          "
        >
          <legend>채용 예정 시기</legend>
          <!-- <div>
                        <input type="checkbox" class="one_to_one_input" id="employment1" @focus="inputClick(7)"
                            @blur="inputRemove(7)" value="true" v-model="schedules[0]">
                        <label for="employment1">6월</label>
                    </div>
                    <div>
                        <input type="checkbox" class="one_to_one_input" id="employment2" @focus="inputClick(7)"
                            @blur="inputRemove(7)" value="true" v-model="schedules[1]">
                        <label for="employment2">7월</label>
                    </div> -->
          <div>
            <input
              type="checkbox"
              class="one_to_one_input"
              id="employment3"
              @focus="inputClick(7)"
              @blur="inputRemove(7)"
              value="true"
              v-model="schedules[2]"
            />
            <label for="employment3">8월</label>
          </div>
          <div>
            <input
              type="checkbox"
              class="one_to_one_input"
              id="employment4"
              @focus="inputClick(7)"
              @blur="inputRemove(7)"
              value="true"
              v-model="schedules[3]"
            />
            <label for="employment4">9월</label>
          </div>
          <div>
            <input
              type="checkbox"
              class="one_to_one_input"
              id="employment5"
              @focus="inputClick(7)"
              @blur="inputRemove(7)"
              value="true"
              v-model="schedules[4]"
            />
            <label for="employment5">10월</label>
          </div>
          <div>
            <input
              type="checkbox"
              class="one_to_one_input"
              id="employment6"
              @focus="inputClick(7)"
              @blur="inputRemove(7)"
              value="true"
              v-model="schedules[5]"
            />
            <label for="employment6">11월</label>
          </div>
        </fieldset>
        <fieldset>
          <legend>채용 지원 필요 솔루션</legend>
          <div class="solution_box">
            <input
              type="checkbox"
              class="one_to_one_input"
              id="solution1"
              @focus="inputClick(8)"
              @blur="inputRemove(8)"
              value="RP_/"
              v-model="solution[0]"
            />
            <label for="solution1">채용 계획 검토</label>
          </div>
          <div class="solution_box">
            <input
              type="checkbox"
              class="one_to_one_input"
              id="solution2"
              @focus="inputClick(8)"
              @blur="inputRemove(8)"
              value="JA_/"
              v-model="solution[1]"
            />
            <label for="solution2">직무 분석</label>
          </div>
          <div class="solution_box">
            <input
              type="checkbox"
              class="one_to_one_input"
              id="solution3"
              @focus="inputClick(8)"
              @blur="inputRemove(8)"
              value="PT_/"
              v-model="solution[2]"
            />
            <label for="solution3">서류전형 도구</label>
          </div>
          <div class="solution_box">
            <input
              type="checkbox"
              class="one_to_one_input"
              id="solution4"
              @focus="inputClick(8)"
              @blur="inputRemove(8)"
              value="IT_/"
              v-model="solution[3]"
            />
            <label for="solution4">면접전형 도구</label>
          </div>
          <div class="solution_box">
            <input
              type="checkbox"
              class="one_to_one_input"
              id="solution5"
              @focus="inputClick(8)"
              @blur="inputRemove(8)"
              value="ET_/"
              v-model="solution[4]"
              @click="solutionEtClickInput"
            />
            <label for="solution5">평가 도구 검토</label>
            <input
              type="number"
              style="width: 50px; margin-left: 25px; padding: 5px"
              min="1"
              max="9"
              v-model="solutionEtCount"
              v-if="solutionET"
            />
          </div>
          <div class="solution_box">
            <input
              type="checkbox"
              class="one_to_one_input"
              id="solution6"
              @focus="inputClick(8)"
              @blur="inputRemove(8)"
              value="AF_/"
              v-model="solution[5]"
              @click="solutionAfClickInput"
            />
            <label for="solution6">채용접수 페이지</label>
            <input
              type="number"
              style="width: 50px; margin-left: 25px; padding: 5px"
              min="1"
              max="9"
              v-model="solutionAfCount"
              v-if="solutionAF"
            />
          </div>
        </fieldset>
        <fieldset class="one_to_one_qna">
          <legend>채용 예정 상세 직무</legend>
          <input
            type="text"
            id="jobgroup_input"
            class="one_to_one_input_text"
            placeholder="행정"
            @keyup.enter="addjobgroup"
            v-model="jobgroupText"
          />
          <transition-group name="fade" class="jobgroup_box">
            <p
              class="jobgroup_in_box"
              v-for="(item, index) in jobgroup"
              :key="item"
            >
              {{ item }}
              <font class="jobgroup_remove_btn" @click="removeJobgroup(index)"
                >X</font
              >
            </p>
          </transition-group>
          <small style="padding: 0 0 10px 10px"
            >입력후 Enter키를 눌러주세요.</small
          >
          <small class="length_check"
            >직무 개수: {{ jobgroup.length }} 개</small
          >
        </fieldset>
        <fieldset class="one_to_one_qna">
          <legend>현장지원 전 사전 조사</legend>
          <div class="preliminary_investigation_box">
            <span>기업(기관)의 채용제도 컨설팅을 받은 경험이 있습니까?</span>
            <div class="preliminary_investigation_in_box">
              <p class="preliminary_investigation_radio">
                <input
                  type="radio"
                  id="preliminary_investigation_true1"
                  class="preliminary_investigation"
                  name="preliminary_investigation1"
                  @focus="inputClick(9)"
                  @blur="inputRemove(9)"
                  :value="true"
                  v-model="agreement[0]"
                />
                <label for="preliminary_investigation_true1">예</label>
                <input
                  type="radio"
                  id="preliminary_investigation_false1"
                  class="preliminary_investigation"
                  name="preliminary_investigation1"
                  @focus="inputClick(9)"
                  @blur="inputRemove(9)"
                  :value="false"
                  v-model="agreement[0]"
                />
                <label for="preliminary_investigation_false1">아니오</label>
              </p>
            </div>
          </div>
          <div class="preliminary_investigation_box">
            <span>현재 기업(기관) 채용 직군의 직무기술서를 갖고 계십니까?</span>
            <div class="preliminary_investigation_in_box">
              <p class="preliminary_investigation_radio">
                <input
                  type="radio"
                  id="preliminary_investigation_true2"
                  class="preliminary_investigation"
                  name="preliminary_investigation2"
                  @focus="inputClick(9)"
                  @blur="inputRemove(9)"
                  :value="true"
                  v-model="agreement[1]"
                />
                <label for="preliminary_investigation_true2">예</label>
                <input
                  type="radio"
                  id="preliminary_investigation_false2"
                  class="preliminary_investigation"
                  name="preliminary_investigation2"
                  @focus="inputClick(9)"
                  @blur="inputRemove(9)"
                  :value="false"
                  v-model="agreement[1]"
                />
                <label for="preliminary_investigation_false2">아니오</label>
              </p>
            </div>
          </div>
          <div class="preliminary_investigation_box">
            <span
              >현재 기업의 핵심가치-인재사을 반영한 선발 평가지표를 갖고
              계십니까?</span
            >
            <div class="preliminary_investigation_in_box">
              <p class="preliminary_investigation_radio">
                <input
                  type="radio"
                  id="preliminary_investigation_true3"
                  class="preliminary_investigation"
                  name="preliminary_investigation3"
                  @focus="inputClick(9)"
                  @blur="inputRemove(9)"
                  :value="true"
                  v-model="agreement[2]"
                />
                <label for="preliminary_investigation_true3">예</label>
                <input
                  type="radio"
                  id="preliminary_investigation_false3"
                  class="preliminary_investigation"
                  name="preliminary_investigation3"
                  @focus="inputClick(9)"
                  @blur="inputRemove(9)"
                  :value="false"
                  v-model="agreement[2]"
                />
                <label for="preliminary_investigation_false3">아니오</label>
              </p>
            </div>
          </div>
        </fieldset>
        <fieldset class="one_to_one_qna">
          <legend>개인정보동의 여부</legend>
          <p style="width: 95%; margin: 20px auto; text-align: center">
            ㈜엑스퍼트컨설팅에 제공하는 상기 정보는 “개인정보보호법 제
            15조(개인정보의 수집, 이용)에<br />
            의거하여 이를 수집하거나 본인의 동의를 얻어야 하는 정보임을 알고
            있으며,<br />
            원활한 기업 채용 현장지원 및 상담을 위해 상기 정보 제공에
            동의합니다.
          </p>
          <table class="table">
            <tbody>
              <tr>
                <td>수집/이용 목적</td>
                <td>
                  2023년 기업 채용 현장지원 사업의 지원 활동 및 만족도 조사
                </td>
              </tr>
              <tr>
                <td>수집 항목</td>
                <td>신청자 정보(성명, 회사, 소속부서, 전화번호, 이메일 등)</td>
              </tr>
              <tr>
                <td>보유 및 이용 기간</td>
                <td>상담 및 사후관리 기간(신청 후 2년간)동안 보유 및 이용</td>
              </tr>
              <tr>
                <td>개인정보 수집 동의 거부 권리</td>
                <td>
                  위의 개인정보 수집/이용 에 대한 동의를 거부할 권리가 있습니다.
                  그러나 동의를 거부할 경우 원활한 서비스 이용이 불가합니다.
                </td>
              </tr>
            </tbody>
          </table>
          <div class="agree_box">
            <p class="agree_in_box">
              <input
                type="checkbox"
                id="agree1"
                class="agree"
                @focus="inputClick(10)"
                @blur="inputRemove(10)"
                v-model="agreement[3]"
              />
              <label for="agree1"> 예</label>
            </p>
          </div>
        </fieldset>
        <fieldset class="one_to_one_qna">
          <legend>참여조사 동의</legend>
          <p style="width: 95%; margin: 20px auto; text-align: center">
            23년 기업 채용 현장지원 사업 참여 기업(기관) 및 개인은<br />
            향후 활용 결과 및 활용 실태파악을 위한 설문조사 참여 및 협조에
            동의합니다.
          </p>
          <div class="agree_box">
            <p class="agree_in_box">
              <input
                type="checkbox"
                id="agree2"
                class="agree"
                @focus="inputClick(11)"
                @blur="inputRemove(11)"
                v-model="agreement[4]"
              />
              <label for="agree2"> 예</label>
            </p>
          </div>
        </fieldset>
      </div>
      <button class="submit_btn" @click="qnaSubmit">제출하기</button>
    </div>
  </div>
</template>

<script>
import { ApplicationForParticipation } from "@/apis/index";

export default {
  data() {
    return {
      // 기업명
      corname: "",
      // 기업유형
      corgubun: "",
      // 담당자
      workernm: "",
      // 부서
      rank1: "",
      // 직급
      rank2: "",
      // 연락처
      contactno: "",
      numbollean: true,
      // 이메일
      workeremail: "",
      emailBolean: true,
      // schedules ex) 202206_/202207_/202208_/
      schedules: [false, false, false, false, false, false],
      schedulesAll: "",
      // solution ex) RP_/IT_/AF_/
      solution: [false, false, false, false, false, false],
      solutionAfCount: 0,
      solutionEtCount: 0,
      solutionAll: "",
      // jobgroup ex) IT_/행정_/
      jobgroupText: "",
      jobgroup: [],
      jobgroupAll: "",
      // 조사 여부
      investigationAll: "N_/N_/N_/",
      ipaddress: "",
      // 동의서
      agreement: [false, false, false, false, false],
      solutionAF: false,
      solutionET: false,
    };
  },
  updated() {
    this.schedulesAll = "";
    this.solutionAll = "";
    this.jobgroupAll = "";
    this.investigationAll = "";

    const schedulesVal = [
      "202206_/",
      "202207_/",
      "202208_/",
      "202209_/",
      "202210_/",
      "202211_/",
    ];
    const solutionVal = [
      "RP_/",
      "JA_/",
      "PT_/",
      "IT_/",
      `ET_/${this.solutionEtCount}_/`,
      `AF_/${this.solutionAfCount}_/`,
    ];

    for (let i = 0; i < 7; i++) {
      if (this.schedules[i]) {
        this.schedulesAll += schedulesVal[i];
      }
    }

    for (let i = 0; i < 6; i++) {
      if (this.solution[i]) {
        this.solutionAll += solutionVal[i];
      }
    }

    for (let jobgroup of this.jobgroup) {
      this.jobgroupAll += `${jobgroup}_/`;
    }

    for (let i = 0; i < 3; i++) {
      if (this.agreement[i]) {
        this.investigationAll += "Y_/";
      } else {
        this.investigationAll += "N_/";
      }
    }
  },
  methods: {
    boxExit() {
      this.$emit("exit");
    },
    inputClick(index) {
      document.querySelectorAll("fieldset")[index].style.border =
        "2px groove #d9e021";
    },
    inputRemove(index) {
      document.querySelectorAll("fieldset")[index].style.border =
        "2px groove gray";

      if (index === 5) {
        console.log("ㅎㅇ");

        this.contactno = this.contactno.substr(0, 11);
      }
    },
    addjobgroup() {
      this.jobgroup.push(this.jobgroupText);

      this.jobgroupText = "";
    },
    removeJobgroup(index) {
      this.jobgroup.splice(index, 1);
    },
    onlyKorean() {
      const pattern = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;

      this.corname = this.corname.replace(pattern, "");
      this.rank2 = this.rank2.replace(pattern, "");
    },
    numCheck() {
      if (!this.contactno) {
        this.numbollean = false;
      } else {
        this.numbollean = true;
      }
    },
    solutionAfClickInput() {
      if (this.solutionAF) {
        this.solutionAF = false;
        this.solutionAfCount = 0;
      } else {
        this.solutionAF = true;
      }
    },
    solutionEtClickInput() {
      if (this.solutionET) {
        this.solutionET = false;
        this.solutionEtCount = 0;
      } else {
        this.solutionET = true;
      }
    },
    emailCheck() {
      const exptext =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

      if (!exptext.test(this.workeremail)) {
        this.emailBolean = false;
      } else {
        this.emailBolean = true;
      }
    },
    qnaSubmit() {
      if (
        !this.corname ||
        !this.corgubun ||
        !this.rank1 ||
        !this.rank2 ||
        !this.workernm ||
        !this.contactno ||
        !this.workeremail ||
        !this.schedulesAll ||
        !this.solutionAll ||
        !this.jobgroupAll
      ) {
        alert("입력란을 모두 기재해주세요.");
        return true;
      }

      if (!this.emailBolean || !this.numbollean) {
        alert("양식이 알맞지 않습니다.");
        return true;
      }

      for (let i = 3; i < this.agreement.length; i++) {
        if (!this.agreement[i]) {
          alert("동의는 필수입니다.");
          return true;
        }
      }

      if (this.solutionET) {
        if (!this.solutionEtCount) {
          alert("평가 도구는 최소 1 이상이어야 합니다.");
          return;
        }
      }

      if (this.solutionAF) {
        if (!this.solutionAfCount) {
          alert("채용접수 페이지는 최소 1 이상이어야 합니다.");
          return;
        }
      }

      const Data = {
        // 기업명
        companynm: this.corname,
        // 기업 유형
        companygubun: this.corgubun,
        // 부서
        departname: this.rank1,
        // 담당자
        workername: this.workernm,
        // 직급
        position: this.rank2,
        // 연락처
        contactno: this.contactno,
        // 이메일
        email: this.workeremail,
        // 채용 예정 시기
        schedules: this.schedulesAll,
        // 채용 예정 상세 직무
        jobgroup: this.jobgroupAll,
        // 채용 지원 필요 솔루션
        solution: this.solutionAll,
        // 서류
        beforehand: this.investigationAll,
      };

      ApplicationForParticipation(Data)
        .then(() => {
          alert("제출 완료 되었습니다.");
          this.$emit("exit");
        })
        .catch((err) => {
          console.log("제출 실패하였습니다.", err);
        });
    },
  },
};
</script>

<style scoped>
.one_to_one {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.one_to_one::before {
  content: "";
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  background-color: black;
  position: fixed;
  z-index: -1;
}

.one_to_one_box {
  width: 70%;
  height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: calc(-90vh / 2);
  margin-left: calc(-70% / 2);
  overflow-y: scroll;
  background-color: white;
}

.one_to_one_top_text {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 40px;
  line-height: 60px;
  border-bottom: 1px solid lightgray;
}

.one_to_one_top_img {
  width: 10%;
  position: absolute;
  top: 50px;
  right: 5%;
}

.one_to_one_bot_text {
  width: 90%;
  margin: 20px auto;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.one_to_one_bot_text fieldset {
  width: 48%;
  margin-top: 20px;
  transition-duration: 0.5s;
}

.one_to_one_bot_text fieldset legend {
  margin-left: 10px;
  padding: 0 10px;
}

.one_to_one_bot_text fieldset legend::after {
  content: "●";
  font-size: 10px;
  position: absolute;
  color: #d9e021;
}

.one_to_one_input_text {
  width: 80%;
  display: block;
  margin: 10px auto;
  font-size: 20px;
  line-height: 28px;
  border: 2px solid transparent;
  border-bottom-color: #777;
  padding: 0.2rem 0;
  outline: none;
  background-color: transparent;
  color: #0d0c22;
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.one_to_one_input_text:focus,
.one_to_one_input_text:hover {
  outline: none;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  border-color: #7a9cc6;
}

.one_to_one_qna {
  width: 100% !important;
}

.one_to_one_qna textarea {
  width: 95%;
  height: 300px;
  display: block;
  margin: 10px auto;
  border: none;
  font-size: 18px;
  resize: none;
}

.one_to_one_qna textarea:focus {
  outline: none;
}

.one_to_one_exit {
  color: red;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.submit_btn {
  width: 250px;
  height: 45px;
  border: 0;
  font-size: 18px;
  position: relative;
  bottom: 0;
  left: 50%;
  margin: 30px 0;
  margin-left: calc(-250px / 2);
  color: white;
  background-color: #29384e;
  cursor: pointer;
}

.solution_box {
  margin: 10px 30px;
}

.solution_box input[type="checkbox"] {
  margin-right: 20px;
}

.table {
  width: 95%;
  margin: 15px auto;
  border-collapse: collapse;
}

.table tbody tr td {
  border: 1px solid gray;
  padding: 15px;
  font-size: 14px;
}

.agree_box {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.agree_in_box {
  float: right;
}

.preliminary_investigation_box {
  display: flex;
  justify-content: space-around;
  margin: 25px 0;
}

.preliminary_investigation_box span {
  width: 85%;
}

.preliminary_investigation_radio label {
  margin-right: 10px;
}

.rank_box {
  width: 22% !important;
}

.jobgroup_box {
  width: 95%;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
}

.jobgroup_in_box {
  /* width: 85px; */
  height: 30px;
  border: 1px solid lightgray;
  border-radius: 25px;
  margin: 5px;
  padding: 0 10px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
}

.jobgroup_remove_btn {
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
}

.length_check {
  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
