<template>
  <div id="app">
    <PopUp></PopUp>
    <router-view></router-view>
    <div class="qna_btn_box" @click="qnaClick">
      <img src="./assets/img/exitbutton.png" alt="QnA" />
    </div>
    <ul class="qna_box">
      <li @click="oneToOneBtn">1:1 문의하기</li>
      <li @click="appLicationBtn">참여 신청하기</li>
      <li>
        <a
          :href="require('../src/assets/downloads/test.png')"
          download="(EXC) 23년 기업채용현장지원_리플렛.pdf"
          >브로셔 다운로드</a
        >
      </li>
    </ul>
    <transition name="fade">
      <QnA v-if="oneToOne" v-on:exit="oneToOneBtn"></QnA>
      <ApplicationForParticipation
        v-if="this.$store.state.appLication"
        v-on:exit="appLicationBtn"
      ></ApplicationForParticipation>
    </transition>
  </div>
</template>

<script>
import QnA from "./components/QnA.vue";
import ApplicationForParticipation from "./components/ApplicationForParticipation.vue";
import PopUp from "./components/PopUp.vue";

export default {
  name: "App",
  components: {
    QnA,
    ApplicationForParticipation,
    PopUp,
  },
  data() {
    return {
      qna: false,
      oneToOne: false,
    };
  },
  methods: {
    qnaClick() {
      if (this.qna === true) {
        this.qna = false;
        document
          .querySelector(".qna_btn_box img")
          .setAttribute("src", require("@/assets/img/exitbutton.png"));
        document.querySelector(".qna_box").style.height = "175px";
      } else {
        this.qna = true;
        document
          .querySelector(".qna_btn_box img")
          .setAttribute("src", require("@/assets/img/button.png"));
        document.querySelector(".qna_box").style.height = "0";
      }
    },
    oneToOneBtn() {
      if (this.oneToOne) {
        this.oneToOne = false;
      } else {
        this.oneToOne = true;
      }
    },
    appLicationBtn() {
      this.$store.dispatch("appLicationClick");
    },
  },
};
</script>

<style>
@font-face {
  font-family: "GmarketSansTTF_Medium";
  src: url("./assets/font/GmarketSansTTFMedium.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

body {
  font-family: "GmarketSansTTF_Medium";
}

.qna_btn_box {
  width: 180px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  z-index: 2;
}

.qna_btn_box img {
  width: 100%;
}

.qna_box {
  width: 150px;
  height: 175px;
  position: fixed;
  bottom: 105px;
  overflow: hidden;
  right: 50px;
  transition: 0.5s;
  z-index: 1;
}

.qna_box li {
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  padding-left: 20px;
  border: 1px solid lightgray;
  transition: 0.5s;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 lightgrey;
  background: white;
  cursor: pointer;
}

.qna_box li:hover {
  border: 1px solid transparent;
  color: white;
  background-color: #76c1ff;
}

.qna_box li a {
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.5s;
}

.qna_box li a:hover {
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
