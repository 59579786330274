import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appLication: false,
  },
  getters: {},
  mutations: {
    appLicationClick(state, payload) {
      if (state.appLication) {
        state.appLication = false;
      } else {
        state.appLication = true;
      }
    },
  },
  actions: {
    appLicationClick(context) {
      return context.commit("appLicationClick");
    },
  },
});
