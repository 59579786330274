<template>
    <div class="pop_up" v-if="!close">
        <img :src="require('@/assets/img/pop_up_img.jpg')" alt="엑스퍼트컨설팅 기업 맞춤형 채용도구">
        <a href="https://blog.naver.com/exc1994/222795137296" target="_blank" class="pop_up_btn"></a>
        <div class="pop_up_bot_box">
            <div class="pop_up_bot_left_box">
                <span>오늘 하루 보지 않기</span>
                <input type="checkbox" class="pop_up_close_check" v-model="oneDays">
            </div>
            <div class="pop_up_bot_right_box" @click="popUpClose">
                <span>닫기</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created () {
        if(document.cookie) {
            this.close = true;
        }
    },
    data() {
        return {
            oneDays: false,
            close: false,
        }
    },
    methods: {
        setCookie(name, value, expiredays) {
            const date = new Date();
            date.setDate(date.getDate() + expiredays);
            document.cookie = escape(name) + '=' + escape(value) + '; expires=' + date.toUTCString();
        },
        popUpClose() {
            if (this.oneDays) {
                this.setCookie('popupYN', 'N', 2);
            }

            this.close = true;
        }
    },
}
</script>

<style scoped>

.pop_up {
    position: absolute;
    top: 5%;
    left: 10%;
    background-color: white;
    z-index: 2;
}
.pop_up_btn {
    width: 265px;
    height: 85px;
    position: absolute;
    bottom: 14%;
    right: 4%;
    background-color: transparent;
    cursor: pointer;
}
.pop_up_bot_box {
    display: flex;
    justify-content: space-between;
    padding: .2rem .8rem;
}
.pop_up_bot_right_box {
    cursor: pointer;
}
</style>