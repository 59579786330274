<template>
    <div>
        <ul class="logo_box">
            <li class="logo_in_box">
                <img src="../../assets/img/ministry-of-employment-and-labor.png" alt="고용노동부">
            </li>
            <li class="logo_in_box">
                <img src="../../assets/img/humanresourcesdevelopement.png" alt="한국산업인력공단">
            </li>
            <li class="logo_in_box">
                <img src="../../assets/img/exc-logo.png" alt="엑스퍼트컨설팅">
            </li>
        </ul>
        <Main></Main>
        <Manual></Manual>
        <ManualOrder></ManualOrder>
    </div>
</template>

<script>
import Main from '../../components/Main.vue';
import Manual from '../../components/Manual.vue';
import ManualOrder from '../../components/ManualOrder.vue';

export default {
    components: {
        Main,
        Manual,
        ManualOrder
    },
    methods: {
        name() {
            axios({
                url: 'http://localhost:8000/api/get-file',
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file.pdf');
                document.body.appendChild(fileLink);
            
                fileLink.click();
            });
        }
    },
}
</script>

<style>
    .logo_box {
        width: 35%;
        position: absolute;
        bottom: 25px;
        left: 50px;
        display: flex;
        justify-content: space-between;
        z-index: 1;
    }

    .logo_in_box {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>