<template>
    <div class="one_to_one">
        <div class="one_to_one_box">
            <div class="one_to_one_exit" @click="boxExit">
                <img src="../assets/img/X.png" alt="나가기">
            </div>
            <div class="one_to_one_top_text">
                <p>안녕하세요</p>
                <p>
                    <font style="color: #3c3cbd;">기업 채용 현장지원</font> 서비스 1:1 문의란입니다.
                </p>
                <p style="font-size: 20px;">관련하여 궁금하신 내용을 남겨주시면 확인 후 빠르게 답변 드리겠습니다.</p>
                <img src="../assets/img/one-to-one.png" alt="1:1문의" class="one_to_one_top_img">
            </div>
            <div class="one_to_one_bot_text">
                <fieldset>
                    <legend>이름</legend>
                    <input type="text" class="one_to_one_input" @focus="inputClick(0)" @blur="inputRemove(0)"
                        @keyup="onlyKorean" v-model="name" maxlength="10" placeholder="이름">
                </fieldset>
                <fieldset>
                    <legend>연락처</legend>
                    <input type="number" class="one_to_one_input" @focus="inputClick(1)" @blur="inputRemove(1)"
                        @keyup="numCheck" v-model="cellPhone" maxlength="20" placeholder="01012345678">
                    <small class="length_check" style="color: #e90000;" v-show="!numbollean">숫자만 입력해주세요.</small>
                </fieldset>
                <fieldset>
                    <legend>이메일</legend>
                    <input type="text" class="one_to_one_input" @focus="inputClick(2)"
                        @blur="inputRemove(2), emailCheck()" v-model="eMail" placeholder="email">
                    <small class="length_check" style="color: #e90000;" v-show="!eMailbollean">이메일 형식이 맞지 않습니다.</small>
                </fieldset>
                <fieldset class="rank_box">
                    <legend>부서</legend>
                    <input type="text" class="one_to_one_input" @focus="inputClick(3)" @blur="inputRemove(3)"
                        v-model="rank1" placeholder="부서" maxlength="50">
                </fieldset>
                <fieldset class="rank_box">
                    <legend>직급</legend>
                    <input type="text" class="one_to_one_input" @focus="inputClick(3)" @blur="inputRemove(3)"
                        @keyup="onlyKorean" v-model="rank2" placeholder="직급" maxlength="10">
                </fieldset>
                <fieldset class="one_to_one_qna">
                    <legend>문의 내용</legend>
                    <textarea class="one_to_one_input" @focus="inputClick(4)" @blur="inputRemove(4)" v-model="inquiry"
                        maxlength="4000" placeholder="문의 내용"></textarea>
                    <small class="length_check">{{ inquiry.length }}/4000</small>
                </fieldset>
            </div>
            <button class="submit_btn" @click="qnaSubmit">제출하기</button>
        </div>
    </div>
</template>

<script>
import { QnA } from '@/apis/index';

export default {
    data() {
        return {
            name: '',
            cellPhone: '',
            numbollean: true,
            eMail: '',
            eMailbollean: true,
            rank1: '',
            rank2: '',
            inquiry: ''
        }
    },
    methods: {
        boxExit() {
            this.$emit('exit');
        },
        inputClick(index) {
            document.querySelectorAll('fieldset')[index].style.border = '2px groove #d9e021';
        },
        inputRemove(index) {
            document.querySelectorAll('fieldset')[index].style.border = '2px groove gray';
        },
        onlyKorean() {
            const pattern = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;

            this.name = this.name.replace(pattern, '');
            this.rank2 = this.rank2.replace(pattern, '');
        },
        numCheck() {
            if (!this.cellPhone) {
                this.numbollean = false;
            } else {
                this.numbollean = true;
            }
        },
        emailCheck() {
            const exptext = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

            if (!exptext.test(this.eMail)) {
                this.eMailbollean = false;
            } else {
                this.eMailbollean = true;
            }

        },
        qnaSubmit() {
            if (this.name !== '' && this.cellPhone !== '' && this.eMail !== '' && this.rank1 !== '' && this.rank2 !== '' && this.inquiry !== '') {
                if (this.numbollean && this.eMailbollean) {
                    const data = {
                        name: this.name,
                        contactno: this.cellPhone,
                        email: this.eMail,
                        deptnm: this.rank1,
                        position: this.rank2,
                        question: this.inquiry,
                        Authorization: process.env.VUE_APP_TOKEN,
                    }

                    QnA(data)
                        .then(() => {
                            alert('제출이 완료 되었습니다.');
                            this.$emit('exit');
                        })
                        .catch((err) => {
                            alert('문제가 발생하였습니다.\n 나중에 다시 시도 해주세요.');
                            console.log('제출 실패하였습니다.', err);
                        });
                    
                } else {
                    alert('제출 형식이 맞지 않습니다.\n다시 한 번 확인해주십시오.');
                }
            } else {
                alert('입력하지 않은 값이 있습니다.\n다시 한 번 확인해주십시오.');
            }
            
        }
    },
}
</script>

<style scoped>





.one_to_one {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.one_to_one::before {
    content: '';
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    background-color: black;
    position: fixed;
    z-index: -1;
}

.one_to_one_box {
    width: 70%;
    height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: calc(-90vh / 2);
    margin-left: calc(-70% / 2);
    overflow-y: scroll;
    background-color: white;
}

.one_to_one_top_text {
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 40px;
    line-height: 60px;
    border-bottom: 1px solid lightgray;
}

.one_to_one_top_img {
    width: 10%;
    position: absolute;
    top: 50px;
    right: 5%;
}

.one_to_one_bot_text {
    width: 90%;
    margin: 20px auto;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.one_to_one_bot_text fieldset {
    width: 48%;
    margin-top: 20px;
    transition-duration: 0.5s;
}

.one_to_one_bot_text fieldset legend {
    margin-left: 10px;
    padding: 0 10px;
}

.one_to_one_bot_text fieldset legend::after {
    content: '●';
    font-size: 10px;
    position: absolute;
    color: #d9e021;
}

.rank_box {
    width: 22% !important;
}

.one_to_one_input {
    width: 80%;
    display: block;
    margin: 10px auto;
    font-size: 20px;
    font-family: 'GmarketSansTTF_Medium';

    line-height: 28px;
    border: 2px solid transparent;
    border-bottom-color: #777;
    padding: .2rem 0;
    outline: none;
    background-color: transparent;
    color: #0d0c22;
    transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.one_to_one_input:focus, .one_to_one_input:hover {
    outline: none;
    padding: .2rem 1rem;
    border-radius: 1rem;
    border-color: #7a9cc6;
}

.one_to_one_qna {
    width: 100% !important;
}

.one_to_one_qna textarea {
    width: 95%;
    height: 300px;
    display: block;
    margin: 10px auto;
    border: none;
    font-size: 18px;
    resize: none;
}

.one_to_one_qna textarea:focus {
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.one_to_one_exit {
    color: red;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.submit_btn {
    width: 250px;
    height: 45px; 
    border: 0;
    font-size: 18px;
    position: relative;
    bottom: 2.5vh;
    left: 50%;
    margin:  30px 0;
    margin-left: calc(-250px / 2);
    color: white;
    background-color: #29384e;
    cursor: pointer;
}

.length_check {
    float: right;
    margin-bottom: 10px;
    margin-right: 10px;
}
</style>