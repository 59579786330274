<template>
  <div class="main">
      <div class="main_logo">
        <img src="../assets/img/ncs-logo.png" alt="국가직무능력표준">
      </div>
      <div class="main_tit">
          <p class="main_tit_h">기업채용 현장지원</p>
          <p class="main_tit_p"><strong style="font-size: 24px; color: deepskyblue;">한국산업인력공단</strong> 주관, HR전문가 <strong style="font-size: 24px; color: deepskyblue;">엑스퍼트컨설팅</strong> 수행으로<br>
          공공기관 및 민간기업의 채용에 활용할 수 있는<br>
          NCS 기반의 맞춤형 채용 평가도구 및 채용접수시스템을<br>
          무료로 제공하는 사업입니다.</p>
      </div>
      <div class="menu_box">
          <ul class="menu_in_box">
                <li class="job_analysis" @click="menuClick(1)">
                    직무분석
                </li>
                <li class="interview_tool" @click="menuClick(3)">
                    면접전형<br>
                    평가도구
                </li>
                <li class="recruit_plan" @click="menuClick(0)">
                    채용<br>
                    계획<br>
                    수립
                </li>
                <li class="recruit_system" @click="menuClick(4)">
                    채용 접수 시스템
                </li>
                <li class="document_tool" @click="menuClick(2)">
                    서류전형<br>
                    평가도구
                </li>
          </ul>
      </div>
      <div class="animate_scroll_box">
        <div class="animate_scroll">
            <div class="animate_scroll_bar"></div>
            <p>Scroll</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    mounted () {
        const scroll = document.querySelector(".animate_scroll_bar");

		scroll.animate([
			{transform: 'translate(0px)'},
			{transform: 'translateY(40px)'},
			{transform: 'translate(0px)'}
		],{
			duration: 2000,
			iterations: Infinity
		});
    },
    methods: {
        menuClick(index) {
            console.log(index);

            const menuHeight = document.querySelector(".main").offsetHeight;

            window.scrollTo({top: menuHeight, behavior:'smooth'});

            const manualMenu = document.querySelectorAll('.menu');

            manualMenu[index].click();
            
            manualMenu[index].classList.remove('bounce-top');
            manualMenu[index].style.animationDelay = '0.5s';
            setTimeout(function() {
                manualMenu[index].className += ' bounce-top';
            }, 1)
        },
    },
}
</script>

<style scoped>
.main {
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url(../assets/img/main-background.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.main_logo {
    width: 80%;
    padding-top: 50px;
    margin-left: 50px;
}

.main_tit {
    width: 650px;
    padding: 2%;
    position: relative;
    top: 10%;
    left: 5%;
}

.main_tit_exc {
    width: 40%;
    text-align: right;
    color: white;
}

.main_tit_h {
    width: 75%;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;
    font-size: 60px;
    position: relative;
    color: #d9e021;
}

.main_tit_h::after {
    content: '';
    width: 5%;
    height: 15%;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 3px solid #d9e021;
    border-left: 3px solid #d9e021;
}

.main_tit_h::before {
    content: '';
    width: 5%;
    height: 15%;
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom: 3px solid #d9e021;
    border-right: 3px solid #d9e021;
}

.main_tit_p {
    font-size: 20px;
    line-height: 30px;
    color: white;
}

.menu_box {
    width: 40%;
    height: 50%;
    position: absolute;
    bottom: 100px;
    right: 30px;
}

.menu_in_box {
    width: 100%;
    height: 100%;
    position: relative;
}

.job_analysis {
    width: 34%;
    height: 30%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 45%;
    color: white;
    z-index: 1;
}

.job_analysis::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #15a0cf;
    z-index: -1;
}

.interview_tool {
    width: 55%;
    height: 39%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1%;
    right: 0;
    color: white;
    z-index: 1;
}

.interview_tool::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #1b7cc6;
    z-index: -1;
}

.recruit_plan {
    width: 21%;
    height: 70%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -10%;
    right: 0;
    color: white;
    z-index: 1;
}

.recruit_plan::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #adb52b;
    z-index: -1;
}

.recruit_system {
    width: 79%;
    height: 28.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 1.5%;
    left: 0;
    font-size: 24px;
    color: white;
    z-index: 1;
}

.recruit_system::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #263c5d;
    z-index: -1;
}

.document_tool {
    width: 35%;
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15%;
    left: 10%;
    font-size: 24px;
    color: white;
    z-index: 1;
}

.document_tool::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: #2b184b;
    z-index: -1;
}

.animate_scroll {
    width: 30px;
    height: 80px;
    margin-left: calc(-30px / 2);
    border: 2px solid white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
}

.animate_scroll_bar {
    width: 20px;
    height: 20px;
    margin-top: 10px;
    border-radius: 20px;
    background-color: white;
}

.animate_scroll p {
    bottom: -35px;
    position: absolute;
    color: white;
}
</style>