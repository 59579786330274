import axios from 'axios';

const config = {
    baseUrl: 'https://www.recruitsupport.kr/api/'
};

// 1:1 문의하기 작성
export function QnA(data) {
    return axios.post(`${config.baseUrl}qna/`, data);
}

// 참여 신청하기 작성
export function ApplicationForParticipation(data) {
    return axios.post(`${config.baseUrl}app/`, data);
}