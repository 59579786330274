<template>
  <div class="order">
    <div class="order_img_box">
      <img src="../assets/img/step1.png" alt="스탭1" @click="appLicationClick" style="cursor: pointer;">
      <img src="../assets/img/step2.png" alt="스탭2">
      <img src="../assets/img/step3.png" alt="스탭3">
      <img src="../assets/img/step4.png" alt="스탭4">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    appLicationClick() {
      this.$store.dispatch('appLicationClick');
    }
  },
}
</script>

<style scoped>
.order {
  width: 100%;
  padding: 100px 0;
  position: relative;
  background-color: #15253d;
}
.order_img_box {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_img_box img {
  width: 250px;
}
</style>