<template>
  <div class="manual">
    <div class="manual_top_box">
      <ul class="manual_menu_box">
        <li class="menu" @click="menuOne" style="animation-delay: 2s">
          <img src="../assets/img/1On-site support.png" alt="메뉴1" />
          <p>채용계획수립</p>
        </li>
        <li class="menu" @click="menuTwo" style="animation-delay: 1.5s">
          <img src="../assets/img/2On-site support.png" alt="메뉴2" />
          <p>직무분석</p>
        </li>
        <li class="menu" @click="menuThree" style="animation-delay: 1s">
          <img src="../assets/img/3On-site support.png" alt="메뉴3" />
          <p>서류전형<br />평가도구</p>
        </li>
        <li class="menu" @click="menuFour" style="animation-delay: 0.5s">
          <img src="../assets/img/4On-site support.png" alt="메뉴4" />
          <p>면접전형<br />평가도구</p>
        </li>
        <li class="menu" @click="menuFive">
          <img src="../assets/img/5On-site support.png" alt="메뉴5" />
          <p>채용 접수<br />시스템</p>
        </li>
      </ul>
      <h1 class="manual_tit">RECRUITMENT</h1>
    </div>
    <p style="margin-left: 420px; color: #1163dc;">* 상단 항목을 클릭하시면 자세한 지원 내용을 확인하실 수 있습니다.</p>
    <div class="manual_bot_box" v-html="manualText" v-if="manualText">
      <!-- 메뉴얼 설명글 -->
    </div>
    <div class="custom-shape-divider-bottom-1652419742">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
          opacity=".25"
          class="shape-fill"
        ></path>
        <path
          d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
          opacity=".5"
          class="shape-fill"
        ></path>
        <path
          d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      manualText:
        '<p class="manual_bot_nav_tit">채용계획수립</p>\
                  <div class="manual_bot_in_box">\
                      <h1 class="manual_bot_tit">■ 채용 계획 검토</h1>\
                      <div class="manual_bot_in_text_box">\
                          <p>공정채용 가이드에 근거한 채용계획 점검 및 개선 방향 제시</p>\
                          <ul class="manual_ul">\
                              <li class="manual_li">신청완료 후 최소 4일 이후 수령 가능</li>\
                          </ul>\
                      </div>\
                  </div>',
      menuIdx: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", function () {
      const userScroll = document.documentElement.scrollTop;

      if (userScroll >= 400) {
        const menuLen = document.querySelectorAll(".menu").length;
        
        for (let i = 0; i < menuLen; i++) {
          const btnEl = document.querySelectorAll('.menu')[i].classList;
          const menu = document.querySelectorAll(".menu");

          if(!btnEl.contains('roll-in-left')) {

            menu[i].className += " roll-in-left";
            menu[i].style.opacity = "1";
          }

          setTimeout(function() {
            menu[i].style.animationDelay = '0.5s';
          }, 3000);
        };
      };
    });
  },
  methods: {
    menuOne() {
      this.manualText =
        '<p class="manual_bot_nav_tit">채용계획수립</p>\
            <div class="manual_bot_in_box">\
                <h1 class="manual_bot_tit">■ 채용 계획 검토</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>공정채용 가이드에 근거한 채용계획 점검 및 개선 방향 제시</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 4일 이후 수령 가능</li>\
                    </ul>\
                </div>\
            </div>';
    },
    menuTwo() {
      this.manualText =
        '<p class="manual_bot_nav_tit">직무분석</p>\
            <div class="manual_bot_in_box">\
                <h1 class="manual_bot_tit">■ 직무기술서</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>(NCS개발)SME 인터뷰 기반 직무별<br>\
                    KSAO/자격요건/직무수행능력/직업기초능력/평가영역 도출 및 직무기술서 개발</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">\
                        <strong>NCS개발직무</strong>: 신청완료 후 최소 5일 이후 수령 가능(SME 인터뷰 기간 제외)\
                        </li>\
                        <li class="manual_li">\
                        <strong>NCS미개발직무</strong>: 신청완료 후 최소 7일 이후 수령 가능(SME 인터뷰 기간 제외)\
                        </li>\
                    </ul>\
                </div>\
            </div>';
    },
    menuThree() {
      this.manualText =
        '<p class="manual_bot_nav_tit">서류전형 평가도구</p>\
            <div class="manual_bot_in_box">\
                <h1 class="manual_bot_tit">■ 채용공고문</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>채용전형 안내 및 유의사항 포함 공고문 개발</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 3일 이후 수령 가능</li>\
                    </ul>\
                </div>\
                <h1 class="manual_bot_tit">■ 입사지원서</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>직무 맞춤형 필요 정보 항목 개발 (필요 자격 등)</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 3일 이후 수령 가능</li>\
                    </ul>\
                </div>\
                <h1 class="manual_bot_tit">■ 자기소개서</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>자기소개서 질문 개발</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 3일 이후 수령 가능</li>\
                    </ul>\
                </div>\
                <h1 class="manual_bot_tit">■ 서류전형 평가적도</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>자격증, 업무경험 등 평가 기준 및 평가표, 평가 척도 개발</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 3일 이후 수령 가능</li>\
                    </ul>\
                </div>\
            </div>';
    },
    menuFour() {
      this.manualText =
        '<p class="manual_bot_nav_tit">면접전형 평가도구</p>\
            <div class="manual_bot_in_box">\
                <h1 class="manual_bot_tit">■ 문항개발</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>채용직무 경험/상황면접 개발</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 5일 이후 수령 가능(SME 인터뷰 기간 제외)</li>\
                    </ul>\
                </div>\
                <h1 class="manual_bot_tit">■ 면접전형 평가척도</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>면접전형 평가 기준 및 평가표 개발</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 5일 이후 수령 가능</li>\
                    </ul>\
                </div>\
            </div>';
    },

    menuFive() {
      this.manualText =
        '<p class="manual_bot_nav_tit">채용 접수 시스템</p>\
            <div class="manual_bot_in_box">\
                <h1 class="manual_bot_tit">■ 접수사이트</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>기관/기업 맞춤형 접수사이트 개설</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 3일 이후 수령 가능</li>\
                    </ul>\
                </div>\
                <h1 class="manual_bot_tit">■ 입사지원</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>지원자 입사지원서 및 자기소개서 접수 시스템 오픈</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 5일 이후 수령 가능(완성된 채용공고문 있을 시)</li>\
                    </ul>\
                </div>\
                <h1 class="manual_bot_tit">■ 전형 운영</h1>\
                <div class="manual_bot_in_text_box">\
                    <p>접수현황, 전형별 평과결과 등 채용 운영을 위한 제반사항 제공</p>\
                    <ul class="manual_ul">\
                        <li class="manual_li">신청완료 후 최소 5일 이후 수령 가능</li>\
                    </ul>\
                </div>\
            </div>';
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Jalnan";
  src: url("../assets/font/Jalnan.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

.manual {
  width: 100%;
  position: relative;
}

/* 위쪽 부분 */
.manual_top_box {
  width: 100%;
  height: 500px;
  position: relative;
}

.manual_menu_box {
  width: 90%;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.manual_tit {
  width: 100%;
  position: absolute;
  top: 350px;
  left: 0;
  text-align: center;
  font-size: 56px;
  color: #0b1c35;
  font-family: "Jalnan";
}

/* 아래쪽 부분 */
.manual_bot_box {
  width: 100%;
  padding-bottom: 200px;
  display: flex;
  position: relative;
  border-top: 2px solid lightgray;
  bottom: 0;
}

.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  color: white;
  cursor: pointer;
  opacity: 0;
}

.menu p {
  position: absolute;
}

.menu img {
  width: 100%;
}

.menu:nth-child(2n) {
  color: black;
}

.menu:nth-child(1) {
  top: 40px;
}

.menu:nth-child(2) {
  top: -20px;
}

.menu:nth-child(3) {
  top: -30px;
}

.menu:nth-child(4) {
  top: -30px;
}

.menu:nth-child(5) {
  top: 30px;
}

.manual_bot_nav_tit {
  width: 300px;
  height: 50px;
  line-height: 55px;
  font-size: 30px;
  padding-left: 25px;
  border-radius: 0 25px 0 0;
  position: absolute;
  left: 80px;
  top: -51px;
  color: white;
  background-color: #0b1c35;
}

.manual_bot_flex {
  width: 50%;
}

.manual_bot_in_box {
  width: 100%;
  margin-left: 90px;
  margin-top: 20px;
}

.manual_bot_tit {
  color: #00bdff;
  margin-top: 30px;
}

.manual_bot_in_text_box {
  margin: 15px 0 0 50px;
  line-height: 25px;
}

.manual_bot_in_text_box p {
  font-size: 20px;
}

.manual_bot_in_text_box ul {
  margin-left: 20px;
}

.manual_ul,
.manual_li {
  list-style-type: disc;
}

.manual_bot_flex:last-child {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: ; */
}

.manual_bot_flex:last-child img {
  width: 250px;
}

.custom-shape-divider-bottom-1652419742 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1652419742 svg {
  position: relative;
  display: block;
  width: calc(113% + 1.3px);
  height: 120px;
}

.custom-shape-divider-bottom-1652419742 .shape-fill {
  fill: #15253d;
}

.roll-in-left {
  -webkit-animation: roll-in-left 0.6s ease-out both;
  animation: roll-in-left 0.6s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-13 14:32:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
    transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
    transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

.bounce-top {
	-webkit-animation: bounce-top 0.9s both;
	        animation: bounce-top 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-16 11:22:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

</style>
